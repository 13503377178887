<template>
  <!-- 学期管理 -->
  <div class="wrap">
    <div class="box-con">
      <el-form class="params-wrap select" :inline="true" v-if="manage||chief" :model="queryParams" >
        <el-form-item class="area_img">
          <!-- <img class="area_select" src="@/assets/images/operate/area_select.svg" alt=""> -->
          <area-tree @setQueryParams="handleQuery" class="year" @clear="clearArea(true)"></area-tree>
        </el-form-item>
        <el-form-item>
          <el-select size="small" placeholder="请选择学校" clearable filterable v-model="queryParams.schoolId" @change="resetPageAndRefresh">
            <el-option v-for="item in schoolType" :label="item.schoolName" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="success" icon="el-icon-search" @click="resetPageAndRefresh">查询</el-button>
        </el-form-item>
        <el-form-item class="fr">
          <el-button size="small" icon="el-icon-plus" @click="handleForm(false)" v-if="OA.includes('yygl:kbgl:xqgl:add')" >新增</el-button>
          <el-button size="small" v-if="OA.includes('yygl:kbgl:xqgl:editor')" @click="isEditor=!isEditor" :type="isEditor ? 'success' : ''">{{isEditor ? '完成' : '编辑'}}</el-button>
        </el-form-item>
      </el-form>
    
      <!-- 列表 -->
      <div v-if="storeTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <paged-list v-model="queryParams" :data="storeList" :isEditor="isEditor" :total="storeTotal" :span='12' @refresh="refresh">
        <template slot-scope="scope">
          <div class="list">
            <div class="con-head">
              <div class="area" v-if="scope.row.extend">
                <p class="con-txt w90">{{ scope.row.extend.schoolName?scope.row.extend.schoolName:'-' }}</p>
                <p class="mission">学校名称</p>
              </div>
              <div class="area">
                <p class="con-txt">{{ scope.row.schoolYear?scope.row.schoolYear:'-' }}</p>
                <p class="mission">学年</p>
              </div>
              <div class="area">
                <p class="con-txt">{{ scope.row.name?scope.row.name:'-' }}</p>
                <p class="mission">学期</p>
              </div>
              <div class="area">
                <p class="con-txt">{{ scope.row.beginOn?scope.row.beginOn:'-' }}</p>
                <p class="mission">开始时间</p>
              </div> 
              <div class="area">
                <p class="con-txt">{{ scope.row.endOn?scope.row.endOn:'-' }}</p>
                <p class="mission">结束时间</p>
              </div> 
            </div>
            <div class="con-con cfx">
              <div class="time-fl fl">
                <div class="time">
                  <span class="contit">添加时间</span>
                  <span class="condel">{{ scope.row.addTime?scope.row.addTime:'-' }}</span>
                </div>
                <div class="time">
                  <span class="contit">备注</span>
                  <span class="condel">{{ scope.row.remark?scope.row.remark:'-' }}</span>
                </div>
              </div>
              <div class="time-fr fr">
                <div class="time">
                  <span class="contit">总周数</span>
                  <span class="condel">{{ scope.row.weeks?scope.row.weeks:'-' }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template slot="control" slot-scope="scope">
          <el-button type="primary"  v-if="OA.includes('yygl:kbgl:xqgl:editor')" @click="handleForm(true, scope.row,afterHandleForm)" icon="el-icon-edit" title="编辑" circle></el-button>
          <el-button type="danger"  v-if="OA.includes('yygl:kbgl:xqgl:del')" @click="handleDelete({id: scope.row.id})" icon="el-icon-delete" title="删除" circle></el-button>
        </template>
      </paged-list>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑学期' : '添加学期'" :visible.sync="aVisible" width="600px" :close-on-click-modal="false">
      <el-form label-width="110px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="所属学校：" prop="schoolId">
          <el-select clearable filterable v-model="form.schoolId" class="w350">
            <el-option v-for="item in schoolType" :label="item.schoolName" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学年：" prop="schoolYear">
          <el-input placeholder="请输入学年" v-model="form.schoolYear" class="w350"></el-input>
        </el-form-item>
        <el-form-item label="学期：" prop="name">
          <el-select v-model="form.name" placeholder="请选择学期" class="w350">
            <el-option label="第一学期" value="第一学期"></el-option>
            <el-option label="第二学期" value="第二学期"></el-option>
          </el-select>
          <!-- <el-input placeholder="请输入学期" v-model="form.name" class="w350"></el-input> -->
        </el-form-item>
        <el-form-item label="开始/结束：">
          <el-date-picker v-model="timeRange" @change="getTimeRange($event,'beginOn','endOn')" type="daterange" value-format="yyyy-MM-dd" :picker-options="recentOptions" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
        </el-form-item>
        <el-form-item label="总周数：" prop="weeks">
          <el-input placeholder="请输入总周数" v-model="form.weeks" class="w350"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input placeholder="请输入备注" v-model="form.remark" class="w350"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageMixin from "@/mixins/pageMixin";
import PagedList from "@/components/common/PagedList";
import AreaTree from "@/components/common/AreaTree";
import datePicker from "@/mixins/datePicker";
import { mapState, mapActions } from "vuex";
export default {
  name:'TermManagement',
  components: {
    PagedList,
    AreaTree
  },
  mixins: [pageMixin, datePicker],
  data() {
    return {
      schoolType: '',
      /** 角色权限 */
      member: false, ceo: false, manage: false, chief: true,
      form: {
        id: '',
        schoolId: '',
        schoolYear: '',
        name: '',
        beginOn: '',
        endOn: '',
        weeks: '',
        remark: '',
      },
      formRules:{
        schoolId: [{ required: true, message: '请选择学校', trigger: 'change' }],
        schoolYear: [{ required: true, message: '请输入学年', trigger: 'blur' }],
        name: [{ required: true, message: '请输入学期', trigger: 'blur' }],
        weeks: [{ required: true, message: '请输入总周数', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
      },
      queryParams: {
        schoolId: null,
      },
      statusOptions: [],

      pageApi:'semesterList',
      insertApi: 'semesterAdd',
      editorApi: 'semesterEdit',
      deleteApi: 'semesterDelete',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("termManage", ["storeList", "storeTotal"]),
  },
  methods: {
    ...mapActions("commonOptions", ["getSchoolCombo"]),
    ...mapActions("termManage", ["semesterList", "semesterAdd", "semesterEdit", "semesterLoad", "semesterDelete",]),
    async afterHandleForm(row){
      this.timeRange = [ row.beginOn, row.endOn ]
    },
    handleQuery(data) {
      this.clearArea(false)
      data.forEach(ele => {
        if ((ele + "").length === 9) { this.queryParams.province = ele }
        if ((ele + "").length === 12) { this.queryParams.city = ele }
        if ((ele + "").length === 15) { this.queryParams.area = ele }
        if ((ele + "").length < 9) { this.queryParams.schoolId = ele }
      });
      this.refresh();
    },
    clearArea(isRefresh){
      this.queryParams.province = null;
      this.queryParams.city = null;
      this.queryParams.area = null;
      this.queryParams.schoolId = null;
      if(isRefresh) this.refresh();
    },
  },
  async mounted() {
    this.schoolType = await this.getSchoolCombo({schoolName:''})
    this.refresh ()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/theme.scss";
.wrap{ width: 100%; height: 100%;
  .box-con{ width: 100%; min-height: 100%; background:#fff; padding: 20px;
    .select{
      .el-form-item { margin-bottom: 17px; }
      /deep/.el-input__icon{ height: auto; }
      .year { 
        /deep/.el-input__inner {  
          background: url('../../assets/images/he/operate/area.svg') no-repeat; 
          background-size: 12px 14px;  
          background-position: 8px 7px; 
          padding: 0 0 0 30px; 
          box-sizing: border-box;
          font-size: 14px;
        }
      }
    }
    .list{
      .con-head{border-bottom:1px solid $c-border;padding-bottom:15px;
        .area,.areas{display: inline-block; padding-right: 16px;
          .con-txt{ font-size: 20px;color: $c-2;line-height: 20px; font-weight: bold;font-family: PingFangSC-Regular, PingFang SC;}
          .txtwidth{ width: 240px; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
          .mission{ font-size: 14px; color: $c-9;line-height: 14px;padding-top: 15px;font-family: PingFangSC-Regular, PingFang SC;}
        }
        .areas{margin-right:0;}
      }
      .con-con{
        .time-fl,.time-fr{width: 50%;display: inline-block;margin-top: 5px;}
        .time{ display: inline-block; font-size: 14px;padding-top:10px;
          .contit{color: $c-9;padding-right: 10px; display: inline-block; overflow: hidden; }
          .condel{color: $c-2; width: 200px; display: inline-block; overflow: hidden; text-overflow:ellipsis; white-space: nowrap; }
        }
      }
    }
  }
}
.w410{ width: 410px !important; }
.w90 { width: 90px !important; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.w350 { width: 350px !important;  }
.dialog-footer { margin-right: 100px;
  .el-button { width: 140px; margin-right: 20px; }
}
</style>